
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import { AgentDetail } from '@/types/agent'
@Component({
  name: 'AgentList'
})
@KeepAlive
export default class AgentList extends Vue {
  private searchInfo = {
    agentName: '',
    isDel: ''
  }

  private loading = false
  private info: Array<AgentDetail> = []

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.agent.selectAgentByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then((res) => {
      this.info = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增
  agentAdd () {
    this.$router.push({
      name: 'agentAdd'
    })
  }

  // 详情
  onDetail (id: string) {
    this.$router.push({
      name: 'agentDetail',
      params: { id }
    })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({
      name: 'agentEdit',
      params: { id }
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除该代理商吗？', '提示').then(() => {
      this.$axios.post(this.$apis.agent.deleteAgent, {
        agentId: id
      }).then(() => {
        this.$message.success('操作成功')
        this.getData()
      })
    })
  }
}
